import { CinesjError } from './cinesj-error';

/**
 * Unexpected error. This error should never be thrown.
 *
 * @param error - Error
 *
 * @extends CinesjError
 */
export class UnexpectedError extends CinesjError {
  constructor(error: unknown) {
    super(`Unexpected error: ${error}`);
  }
}
