import { Sector } from '@cinesj/models';
import {
  ApiVersion,
  CommandError,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Get sector command. Returns the Sector.
 *
 * @returns Returns the Sector. See {@link Sector\}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const sector = await client.run(new GetSectorCommand());
 *
 * console.log(sector);
 * ```
 */
export class GetSectorCommand extends Command {
  private readonly sectorId: string;

  constructor(sectorId: string) {
    super();
    this.sectorId = sectorId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/sectors/${this.sectorId}`,
    };
  }

  public parseError(error: AxiosError<any, any>): CommandError {
    return error;
  }

  public parseResponse(response: AxiosResponse<any, any>): Sector {
    return response.data;
  }
}
