import { EventType, IUpdateEventTypeDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { AxiosResponse } from 'axios';

/**
 * Update event type command. Returns the EventType.
 *
 * @returns Returns the Event type. See {@link EventType}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const eventType = await client.run(new UpdateEventTypeCommand("event-type-id", {...data}));
 *
 * console.log(eventType);
 * ```
 */
export class UpdateEventTypeCommand extends Command {
  private readonly eventTypeId: string;

  private readonly data: IUpdateEventTypeDto;

  constructor(eventTypeId: string, data: IUpdateEventTypeDto) {
    super();

    this.eventTypeId = eventTypeId;
    this.data = data;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/event-types/${this.eventTypeId}`,
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): EventType {
    return response.data;
  }
}
