import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Delete device command. Returns the Device.
 *
 * @returns Returns the Device. See {@link Device}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * await client.run(new DeleteDeviceCommand('device-id'));
 * ```
 */
export class DeleteDeviceCommand extends Command {
  private deviceId: string;

  constructor(deviceId: string) {
    super();

    this.deviceId = deviceId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.delete,
      version: ApiVersion.v2,
      path: `/devices/${this.deviceId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): void {
    return response.data;
  }
}
