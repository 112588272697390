import { plainToInstance } from 'class-transformer';

/**
 * Transforms an array of plain objects to an array of instances of the class Schema
 * @param plainArray Input array
 * @param instance The class Schema to be used to transform the plain array
 * @returns An array of instances of the class Schema
 *
 * @example
 * ```ts
 * import { plainArrayToInstance } from '@cinesj/sdk/core/utils';
 *
 * const plainArray = [
 *   {
 *     id: '1',
 *     name: 'John Doe',
 *   },
 *   {
 *     id: '2',
 *     name: 'Jane Doe',
 *   },
 * ];
 *
 * const instanceArray = plainArrayToInstance(plainArray, UserSchema);
 *
 * console.log(instanceArray);
 *```
 */
export function plainArrayToInstance<T>(plainArray: any[], instance: any): T[] {
  return plainToInstance(instance, plainArray);
}
