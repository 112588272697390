import type { Layout } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get layout command. Returns the Layout.
 *
 * @param layoutId The layout id.
 *
 * @returns Returns the Layout. See {@link Layout}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const layout = await client.run(new GetLayoutCommand('layout-id'));
 *
 * console.log(layout);
 * ```
 */
export class GetLayoutCommand extends Command {
  private readonly layoutId: string;

  constructor(layoutId: string) {
    super();

    this.layoutId = layoutId;
  }

  public getPayload(): CommandPayload<void> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/layouts/${this.layoutId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Layout {
    return response.data;
  }
}
