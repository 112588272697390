import { CinesjError } from './cinesj-error';

/**
 * Authentication error. This error is thrown when the authentication fails. And cannot refresh the token.
 *
 * @extends CinesjError
 */
export class AuthenticationError extends CinesjError {
  public constructor(message: string) {
    super(message);
  }
}
