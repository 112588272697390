import { AppConfigKeys } from '@cinesj/constants';
import type { Config, IUpdateConfigDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Update config command. Returns the Config.
 *
 * @returns Returns the Config. See {@link Config}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const config = await client.run(new UpdateConfigCommand());
 *
 * console.log(config);
 * ```
 */
export class UpdateConfigCommand extends Command {
  protected idOrKey: string;

  protected value: string;

  constructor(idOrKey: string | AppConfigKeys, value: string) {
    super();

    this.idOrKey = idOrKey;
    this.value = value;
  }

  public getPayload(): CommandPayload<IUpdateConfigDto> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/configs/${this.idOrKey}`,
      body: {
        value: this.value,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Config {
    return response.data;
  }
}
