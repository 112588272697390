import { AppConfigKeys } from '@cinesj/constants';
import type { Config } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get config command. Returns the Config.
 *
 * @param idOrKey The Config ID or Key. See {@link AppConfigKeys}
 *
 * @returns Returns the Config. See {@link Config}
 *
 * @throws ConfigNotFoundError if the Config is not found.
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const config = await client.run(new GetConfigCommand());
 *
 * console.log(config);
 * ```
 */
export class GetConfigCommand extends Command {
  private readonly idOrKey?: string;

  constructor(idOrKey: string | AppConfigKeys) {
    super();

    this.idOrKey = idOrKey;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/configs/${this.idOrKey}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Config {
    return response.data;
  }
}
