import type { Config, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  Command,
  CommandPayload,
  HttpMethod,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

export class GetConfigsCommand extends Command {
  protected paginator?: IPaginator;

  constructor(paginator?: IPaginator) {
    super();

    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/configs',
      query: {
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Config[] {
    return response.data;
  }
}
