import {
  ApiVersion,
  CommandError,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Connect bling command. Returns the ConnectBling.
 *
 * @returns Returns the Connect bling. See {@link ConnectBling\}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const connectBling = await client.run(new ConnectBlingCommand());
 *
 * console.log(connectBling);
 * ```
 */
export class ConnectBlingCommand extends Command {
  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/bling/integration/connect',
    };
  }

  public parseError(error: AxiosError<any, any>): CommandError {
    return error;
  }

  public parseResponse(response: AxiosResponse<any, any>): {
    url: string;
  } {
    return response.data;
  }
}
