import type { ICreateUserDto, User } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create user command. Returns the User.
 *
 * @returns Returns the User. See {@link User}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const user = await client.run(new CreateUserCommand({
 *  name: 'John Doe',
 *  email: 'john.doe@example.com',
 *  password: '123456'',
 *  role: UserRole.Customer,
 *  cpf: '12345678910'
 * }));
 *
 * console.log(user);
 * ```
 */
export class CreateUserCommand extends Command {
  private readonly data: ICreateUserDto;

  constructor(data: ICreateUserDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<ICreateUserDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/users',
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): User {
    return response.data;
  }
}
