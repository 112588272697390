import type { IPaginator, Layout } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get layouts command. Returns the Layout.
 *
 * @param roomId The room id.
 * @param pagination The pagination. See {@link IPaginator}
 *
 * @returns Returns the Layout. See {@link Layout}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const layouts = await client.run(new GetLayoutsCommand());
 *
 * console.log(layouts);
 * ```
 */
export class GetLayoutsCommand extends Command {
  private readonly roomId?: string;

  private readonly pagination?: IPaginator;

  constructor(roomId?: string, pagination?: IPaginator) {
    super();

    this.roomId = roomId;
    this.pagination = pagination;
  }

  public getPayload(): CommandPayload<void> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/layouts',
      query: {
        roomId: this.roomId,
        page: this.pagination?.page,
        perPage: this.pagination?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Layout[] {
    return response.data;
  }
}
