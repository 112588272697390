import type { IPaginator, Ticket } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get tickets command.
 *
 * @param reservationId Reservation ID to filter
 * @param paginator Paginator. See {@link Paginator}
 *
 * @returns Returns a list of Ticket. See {@link Ticket}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const tickets = await client.run(new GetTicketsCommand());
 *
 * console.log(tickets);
 * ```
 */
export class GetTicketsCommand extends Command {
  private readonly reservationId?: string;

  private readonly paginator?: IPaginator;

  public constructor(reservationId?: string, paginator?: IPaginator) {
    super();

    this.reservationId = reservationId;
    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/tickets',
      query: {
        reservationId: this.reservationId,
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Ticket[] {
    return response.data;
  }
}
