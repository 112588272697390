import type { ILoginDto, Login } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Login command. This command is used to login the user.
 *
 * @param payload - Login command options. See {@link LoginCommandPayload}
 *
 * @returns Returns the user data and tokens. See {@link Login}
 *
 * @example
 * ```ts
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const userData = await client.run(
 *  new LoginCommand({
 *   email: 'test@example.com',
 *   password: '123456',
 * }),
 * );
 *
 * console.log(userData);
 * ```
 */
export class LoginCommand implements Command<Login> {
  private payload: ILoginDto;

  constructor(payload: ILoginDto) {
    this.payload = payload;
  }

  public getPayload(): CommandPayload<ILoginDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/auth/login',
      body: {
        email: this.payload.email,
        password: this.payload.password,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<Login>): Login {
    return response.data;
  }
}
