import type { IPaginator, Session } from '@cinesj/models';
import { SessionSchema } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { plainArrayToInstance } from '@cinesj/sdk/core/utils';
import type { AxiosResponse } from 'axios';

/**
 * Get Sessions Command. Returns a list of sessions.
 *
 * @returns A list of sessions. See {@link Session}
 *
 * @example
 * ```ts
 * import { CinesjClient, GetSessionsCommand } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({
 *   apiEndpoint: 'https://api.cinesaojose.com.br',
 * });
 *
 * const sessions = await client.run(new GetSessionsCommand());
 *
 * console.log(sessions);
 * ```
 */
export class GetSessionsCommand extends Command {
  private readonly eventId?: string;

  protected paginator?: IPaginator;

  constructor(eventId?: string, paginator?: IPaginator) {
    super();

    this.eventId = eventId;
    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/sessions',
      query: {
        eventId: this.eventId,
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Session[] {
    return plainArrayToInstance(response.data, SessionSchema);
  }
}
