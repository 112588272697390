import { BlingProductSimple } from '@cinesj/models';
import {
  ApiVersion,
  CommandError,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Get bling products command. Returns the BlingProduct[].
 *
 * @returns Returns the Bling product. See {@link BlingProduct[]\}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const blingProduct = await client.run(new GetBlingProductsCommand());
 *
 * console.log(blingProduct);
 * ```
 */
export class GetBlingProductsCommand extends Command {
  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/bling/products',
    };
  }

  public parseError(error: AxiosError<any, any>): CommandError {
    return error;
  }

  public parseResponse(
    response: AxiosResponse<any, any>,
  ): BlingProductSimple[] {
    return response.data;
  }
}
