import { CommandError } from './command';

export class ValidationError extends CommandError {
  errors: any[];

  public constructor(message: string, errors?: any[]) {
    super(message);

    this.errors = errors || [];
  }
}
