import type { Event, ICreateEventDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create event command. Returns the Event.
 *
 * @returns Returns the Event. See {@link Event}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const event = await client.run(new CreateEventCommand());
 *
 * console.log(event);
 * ```
 */
export class CreateEventCommand extends Command {
  private readonly data: ICreateEventDto;

  constructor(data: ICreateEventDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/events',
      body: this.data,
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Event {
    return response.data;
  }
}
