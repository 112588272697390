import type { IUpdateLayoutDto, Layout } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Update layout command. Returns the Layout.
 *
 * @param layoutId The layout id.
 * @param data The layout data. See {@link IUpdateLayoutDto}
 *
 * @returns Returns the Layout. See {@link Layout}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const layout = await client.run(new UpdateLayoutCommand());
 *
 * console.log(layout);
 * ```
 */
export class UpdateLayoutCommand extends Command {
  private readonly layoutId: string;

  private readonly data: IUpdateLayoutDto;

  constructor(layoutId: string, data: IUpdateLayoutDto) {
    super();

    this.layoutId = layoutId;
    this.data = data;
  }

  public getPayload(): CommandPayload<IUpdateLayoutDto> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/layouts/${this.layoutId}`,
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Layout {
    return response.data;
  }
}
