import {
  UnexpectedError,
  BaseCinesjClient,
  ClientOptions,
  Command,
} from '@cinesj/sdk/core';
import {
  getAuthorizationHeader,
  parseAxiosError,
} from '@cinesj/sdk/core/utils';
import axios, { AxiosInstance } from 'axios';

/**
 * Cinesj client. This class is used to execute commands.
 * See {@link BaseCinesjClient} for the interface.
 * See {@link ClientOptions} for the options.
 *
 * @param options - Client options
 *
 * @class
 *
 * @example
 * ```ts
 * import { CinesjClient, GetMoviesCommand } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({
 *   baseUrl: 'https://api.cinesj.com',
 * });
 *```
 */
export class CinesjClient implements BaseCinesjClient {
  protected options: ClientOptions;

  protected httpClient: AxiosInstance;

  constructor(options: ClientOptions) {
    this.options = options;

    this.createHttpClient();
  }

  async run<C extends Command = Command>(
    command: C,
  ): Promise<ReturnType<C['parseResponse']>> {
    try {
      const payload = command.getPayload();

      const authTokens = await this.options.auth?.getAuthToken?.(this);

      const response = await this.httpClient.request({
        baseURL:
          typeof this.options.apiEndpoint === 'function'
            ? this.options.apiEndpoint()
            : this.options.apiEndpoint,
        method: payload.method,
        url: `${payload.version}${payload.path}`,
        params: payload.query,
        headers: {
          ...(payload.headers as any),
          ...(authTokens
            ? {
                Authorization: getAuthorizationHeader(authTokens),
              }
            : {}),
        },
        data: payload.body,
      });

      return command.parseResponse(response);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw parseAxiosError(error, command);
      }

      throw new UnexpectedError(error);
    }
  }

  protected createHttpClient(): void {
    this.httpClient = axios.create();
  }
}
