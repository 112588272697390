/**
 * HTTP methods, as defined in RFC 7231.
 *
 * @see https://tools.ietf.org/html/rfc7231#section-4
 *
 * @enum {string}
 */
// eslint-disable-next-line no-shadow
export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE',
}
