/**
 * The type of authentication token.

 * @enum {string}
 */
// eslint-disable-next-line no-shadow
export enum AuthTokenType {
  jwt = 'jwt',
  device = 'device',
}

/**
 * Authentication token. See {@link ClientOptionsAuth}
 *
 * @param token - The token
 * @param type - The type of token. See {@link AuthTokenType}
 *
 * @interface
 */
export interface AuthToken {
  token: string;
  type: AuthTokenType;
}
