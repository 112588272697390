import { CompleteLayout } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get complete layout command.
 *
 * @param layoutId The layout id.
 *
 * @returns Returns the complete layout. See {@link CompleteLayout}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const layout = await client.run(new GetCompleteLayoutCommand('layout-id'));
 *
 * console.log(layout);
 * ```
 */
export class GetCompleteLayoutCommand extends Command {
  private readonly layoutId: string;

  constructor(layoutId: string) {
    super();

    this.layoutId = layoutId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/layouts/${this.layoutId}/complete`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): CompleteLayout {
    return response.data;
  }
}
