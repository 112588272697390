import type { Ticket } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get ticket command. Returns the Ticket.
 *
 * @returns Returns the Ticket. See {@link Ticket}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const ticket = await client.run(new GetTicketCommand("ticket-id"));
 *
 * console.log(ticket);
 * ```
 */
export class GetTicketCommand extends Command {
  private readonly ticketId: string;

  constructor(ticketId: string) {
    super();

    this.ticketId = ticketId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/tickets/${this.ticketId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Ticket {
    return response.data;
  }
}
