import type { Distributor, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get distributors command. Returns the Distributor.
 *
 * @param pagination Pagination options. See {@link IPaginator}
 *
 * @returns Returns a list of Distributor. See {@link Distributor}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const distributors = await client.run(new GetDistributorsCommand());
 *
 * console.log(distributors);
 * ```
 */
export class GetDistributorsCommand extends Command {
  private readonly pagination?: IPaginator;

  constructor(pagination?: IPaginator) {
    super();

    this.pagination = pagination;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/distributors',
      query: {
        page: this.pagination?.page,
        perPage: this.pagination?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Distributor[] {
    return response.data;
  }
}
