import type { IPaginator, Room } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get rooms command. This command is used to get all rooms.
 * This command requires authentication.
 *
 * @returns Returns an array of rooms. See {@link Room} for the model.
 *
 * @example
 * ```ts
 * import { CinesjClient, GetRoomsCommand } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({
 *   apiEndpoint: 'https://api.cinesaojose.com.br',
 * });
 *
 * const rooms = await client.execute(new GetRoomsCommand());
 *
 * console.log(rooms);
 * ```
 *
 */
export class GetRoomsCommand extends Command {
  private readonly paginator?: IPaginator;

  public constructor(paginator?: IPaginator) {
    super();

    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/rooms',
      query: {
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Room[] {
    return response.data;
  }
}
