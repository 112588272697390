import type { User } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get user command. Returns the User.
 *
 * @returns Returns the User. See {@link User}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const user = await client.run(new GetUserCommand("user-id"));
 *
 * console.log(user);
 * ```
 */
export class GetUserCommand extends Command {
  private userId: string;

  public constructor(userId: string) {
    super();

    this.userId = userId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/users/${this.userId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): User {
    return response.data;
  }
}
