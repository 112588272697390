import {
  AuthenticationError,
  CinesjError,
  CommandError,
  NotFoundError,
  UnexpectedError,
  ValidationError,
  Command,
} from '@cinesj/sdk/core';
import type { AxiosError } from 'axios';
import { HttpStatusCode } from 'axios';

export function parseAxiosError(
  error: AxiosError,
  command: Command,
): CinesjError | undefined {
  if (!error.response) {
    return new UnexpectedError(error);
  }

  if (command.parseError) {
    return command.parseError(error);
  }

  switch (error.response.status) {
    case HttpStatusCode.BadRequest:
      return new ValidationError((error.response.data as any).message);
    case HttpStatusCode.Unauthorized:
      return new AuthenticationError((error.response.data as any).message);
    case HttpStatusCode.NotFound:
      return new NotFoundError((error.response.data as any).message);
    case HttpStatusCode.UnprocessableEntity:
      return new CommandError((error.response.data as any).message);
    default:
      return new UnexpectedError(error);
  }
}
