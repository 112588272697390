import type { File } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get file command. Returns the File.
 *
 * @returns Returns the File. See {@link File}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const file = await client.run(new GetFileCommand());
 *
 * console.log(file);
 * ```
 */
export class GetFileCommand extends Command {
  private readonly fileId: string;

  constructor(fileId: string) {
    super();

    this.fileId = fileId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/files/${this.fileId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): File {
    return response.data;
  }
}
