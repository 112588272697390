import type { PaymentProvider } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get payment provider command. Returns the PaymentProvider.
 *
 * @returns Returns the Payment provider. See {@link PaymentProvider}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const paymentProvider = await client.run(new GetPaymentProviderCommand('payment-provider-id'));
 *
 * console.log(paymentProvider);
 * ```
 */
export class GetPaymentProviderCommand extends Command {
  private readonly paymentProviderId: string;

  public constructor(paymentProviderId: string) {
    super();

    this.paymentProviderId = paymentProviderId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/payment-providers/${this.paymentProviderId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): PaymentProvider {
    return response.data;
  }
}
