import type {
  ICreatePaymentProviderDto,
  PaymentProvider,
} from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create payment provider command. Returns the PaymentProvider.
 *
 * @returns Returns the Payment provider. See {@link PaymentProvider}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const paymentProvider = await client.run(new CreatePaymentProviderCommand());
 *
 * console.log(paymentProvider);
 * ```
 */
export class CreatePaymentProviderCommand extends Command {
  private readonly data: ICreatePaymentProviderDto;

  constructor(data: ICreatePaymentProviderDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<ICreatePaymentProviderDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/payment-providers',
      body: this.data,
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): PaymentProvider {
    return response.data;
  }
}
