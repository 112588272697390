import type { IUpdateRoomDto, Room } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Update room command. Returns the Room.
 *
 * @param roomId The room id.
 * @param data The room data. See {@link IUpdateRoomDto}
 *
 * @returns Returns the Room. See {@link Room}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const room = await client.run(new UpdateRoomCommand("room-id", {...data}));
 *
 * console.log(room);
 * ```
 */
export class UpdateRoomCommand extends Command {
  protected roomId: string;

  protected data: IUpdateRoomDto;

  public constructor(roomId: string, data: IUpdateRoomDto) {
    super();

    this.roomId = roomId;
    this.data = data;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/rooms/${this.roomId}`,
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Room {
    return response.data;
  }
}
