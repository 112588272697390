import type {
  IUpdatePaymentProviderDto,
  PaymentProvider,
} from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Update payment provider command. Returns the PaymentProvider.
 *
 * @returns Returns the Payment provider. See {@link PaymentProvider}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const paymentProvider = await client.run(new UpdatePaymentProviderCommand('payment-provider-id', {...data}));
 *
 * console.log(paymentProvider);
 * ```
 */
export class UpdatePaymentProviderCommand extends Command {
  private paymentProviderId: string;

  private data: IUpdatePaymentProviderDto;

  public constructor(
    paymentProviderId: string,
    data: IUpdatePaymentProviderDto,
  ) {
    super();

    this.paymentProviderId = paymentProviderId;
    this.data = data;
  }

  public getPayload(): CommandPayload<IUpdatePaymentProviderDto> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/payment-providers/${this.paymentProviderId}`,
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): PaymentProvider {
    return response.data;
  }
}
