import { CinesjError } from './cinesj-error';

/**
 * CommandError is thrown when a command fails.
 *
 * @param message - Error message
 *
 * @extends CinesjError
 */
export class CommandError extends CinesjError {
  constructor(message: string) {
    super(message);
    this.name = 'CommandError';
  }
}
