import { Product, ProductType, TicketType } from '@cinesj/models';
import {
  ApiVersion,
  CommandError,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import { AxiosError, AxiosResponse } from 'axios';

export type GetProductsCommandFilter = {
  type?: ProductType;
  metadata?: {
    eventId?: string;
    sessionId?: string;
    sharedStockCRN?: string;
    ticketType?: TicketType;
  };
};

/**
 * Get products command. Returns the Product.
 *
 * @returns Returns the Product. See {@link Product\}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const product = await client.run(new GetProductsCommand());
 *
 * console.log(product);
 * ```
 */
export class GetProductsCommand extends Command {
  private filter: GetProductsCommandFilter;

  public constructor(filter: GetProductsCommandFilter = {}) {
    super();
    this.filter = filter;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/products',
      query: {
        type: this.filter.type,
        'metadata:eventId': this.filter.metadata?.eventId,
        'metadata:sessionId': this.filter.metadata?.sessionId,
        'metadata:sharedStockCRN': this.filter.metadata?.sharedStockCRN,
        'metadata:ticketType': this.filter.metadata?.ticketType,
      },
    };
  }

  public parseError(error: AxiosError<any, any>): CommandError {
    return error;
  }

  public parseResponse(response: AxiosResponse<any, any>): Product[] {
    return response.data;
  }
}
