import type { ICreateSessionDto, Session } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create session command. Returns the Session.
 *
 * @returns Returns the Session. See {@link Session}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const session = await client.run(new CreateSessionCommand({ ...data }));
 *
 * console.log(session);
 * ```
 */
export class CreateSessionCommand extends Command {
  private readonly data: ICreateSessionDto;

  constructor(data: ICreateSessionDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/sessions',
      body: this.data,
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Session {
    return response.data;
  }
}
