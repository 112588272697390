import type { Session } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get session command. Returns the Session.
 *
 * @returns Returns the Session. See {@link Session}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const session = await client.run(new GetSessionCommand('session-id'));
 *
 * console.log(session);
 * ```
 */
export class GetSessionCommand extends Command {
  private readonly sessionId: string;

  constructor(sessionId: string) {
    super();

    this.sessionId = sessionId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: `/sessions/${this.sessionId}`,
    };
  }

  public parseError: undefined;

  // public parseError(error: AxiosError<any, any>): CommandError {
  //   if (error.response?.status === httpStatusCode.badRequest) {
  //     return new ValidationError('Invalid session id');
  //   }
  //   if (error.response?.status === httpStatusCode.notFound) {
  //     return new NotFoundError('Session not found');
  //   }
  //
  //   return error;
  // }

  public parseResponse(response: AxiosResponse<any, any>): Session {
    return response.data;
  }
}
