import { CommandError } from '@cinesj/sdk/core/errors';
import type { AxiosError, AxiosResponse } from 'axios';

import { CommandPayload } from './command-payload';

/**
 * Command interface. This interface is used to define the command to be executed
 * by the client.
 *
 * @abstract
 */
export abstract class Command<T = any, R = any> {
  public abstract getPayload(): CommandPayload;

  public abstract parseError?(error: AxiosError<any>): CommandError;

  public abstract parseResponse(response: AxiosResponse<R>): T;
}
