import type { Distributor } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Delete distributor command.
 *
 * @param id Distributor id to delete.
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const distributor = await client.run(new DeleteDistributorCommand('distributor-id'));
 *
 * console.log(distributor);
 * ```
 */
export class DeleteDistributorCommand extends Command {
  private readonly distributorId: string;

  constructor(distributorId: string) {
    super();

    this.distributorId = distributorId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.delete,
      version: ApiVersion.v2,
      path: `/distributors/${this.distributorId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Distributor {
    return response.data;
  }
}
