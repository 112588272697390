import type { Room } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Delete room command.
 *
 * @param roomId The room id.
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * await client.run(new DeleteRoomCommand("room-id"));
 * ```
 */
export class DeleteRoomCommand extends Command {
  private readonly roomId: string;

  public constructor(roomId: string) {
    super();

    this.roomId = roomId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.delete,
      version: ApiVersion.v2,
      path: `/rooms/${this.roomId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Room {
    return response.data;
  }
}
