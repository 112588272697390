import type { Event, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

import { GetEventsCommandFilters } from './filters';

/**
 * Get events command. Returns a list of events. Allowing to filter by active status and ticket type.
 *
 * @param filters - Filters for get events command. See {@link GetEventsCommandFilters}
 *
 * @returns Returns a list of events. See {@link Event}
 *
 * @example
 * ```ts
 * import { EventTicketType } from '@cinesj/models';
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const events = await client.run(
 *   new GetEventsCommand({
 *     onlyActive: true,
 *     ticketType: EventTicketType.seatPicker,
 *   }),
 * );
 *
 * console.log(events);
 * ```
 */
export class GetEventsCommand extends Command {
  protected filters?: GetEventsCommandFilters;

  protected paginator?: IPaginator;

  constructor(filters?: GetEventsCommandFilters, paginator?: IPaginator) {
    super();

    this.filters = filters;
    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      path: '/events',
      version: ApiVersion.v2,
      query: {
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
        onlyActive: this.filters?.onlyActive,
        ticketType: this.filters?.ticketType,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any[], any>): Event[] {
    return response.data.map<Event>((event: any) => ({
      id: event.id,
      eventTypeId: event.eventTypeId,
      name: event.name,
      slug: event.slug,
      description: event.description,
      distributorId: event.distributorId,
      paymentProviderId: event.paymentProviderId,
      banner: event.banner,
      createdAt: new Date(event.createdAt),
      updatedAt: new Date(event.updatedAt),
    }));
  }
}
