import type { User } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get me command. Returns the current user.
 *
 * @returns Returns the current user. See {@link User}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const user = await client.run(new GetMeCommand());
 *
 * console.log(user);
 * ```
 */
export class GetMeCommand extends Command {
  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/users/me',
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): User {
    return response.data;
  }
}
