import type { File, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get files command. Returns a list of file.
 *
 * @returns Returns a list of files. See {@link File\}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const files = await client.run(new GetFilesCommand());
 *
 * console.log(files);
 * ```
 */
export class GetFilesCommand extends Command {
  private readonly pagainator?: IPaginator;

  constructor(paginator?: IPaginator) {
    super();

    this.pagainator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/files',
      query: {
        page: this.pagainator?.page,
        perPage: this.pagainator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): File[] {
    return response.data;
  }
}
