import type { ICreateRoomDto, Room } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create room command. Returns the Room.
 *
 * @param data The room data. See {@link ICreateRoomDto}
 *
 * @returns Returns the Room. See {@link Room}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const room = await client.run(new CreateRoomCommand());
 *
 * console.log(room);
 * ```
 */
export class CreateRoomCommand extends Command {
  protected readonly data: ICreateRoomDto;

  public constructor(data: ICreateRoomDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/rooms',
      body: this.data,
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Room {
    return response.data;
  }
}
