import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Delete session command. Returns the Session.
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const session = await client.run(new DeleteSessionCommand('session-id'));
 *
 * console.log(session);
 * ```
 */
export class DeleteSessionCommand extends Command {
  private readonly sessionId: string;

  constructor(sessionId: string) {
    super();

    this.sessionId = sessionId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.delete,
      version: ApiVersion.v2,
      path: `/sessions/${this.sessionId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): void {
    return response.data;
  }
}
