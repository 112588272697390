import type { Device, ICreateDeviceDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create device command. Returns the Device.
 *
 * @returns Returns the Device. See {@link Device}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const device = await client.run(new CreateDeviceCommand("device-name"));
 *
 * console.log(device);
 * ```
 */
export class CreateDeviceCommand extends Command {
  private readonly name: string;

  constructor(name: string) {
    super();

    this.name = name;
  }

  public getPayload(): CommandPayload<ICreateDeviceDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/devices',
      body: {
        name: this.name,
      },
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Device {
    return response.data;
  }
}
