import type { EventType, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get event types command.
 *
 * @param paginator The paginator. See {@link IPaginator}
 *
 * @returns Returns a list of Event type. See {@link EventType}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const eventTypes = await client.run(new GetEventTypesCommand());
 *
 * console.log(eventTypes);
 * ```
 */
export class GetEventTypesCommand extends Command {
  private readonly paginator?: IPaginator;

  constructor(paginator?: IPaginator) {
    super();

    this.paginator = paginator;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/event-types',
      query: {
        page: this.paginator?.page,
        perPage: this.paginator?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): EventType[] {
    return response.data;
  }
}
