import type { File as FileModel } from '@cinesj/models';
import {
  ApiVersion,
  Command,
  CommandPayload,
  HttpMethod,
} from '@cinesj/sdk/core';

/**
 * Upload file command. Returns the File.
 *
 * @param file The file to upload.
 *
 * @returns Returns the File. See {@link FileModel}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const file = await client.run(new UploadFileCommand());
 *
 * console.log(file);
 * ```
 */
export class UploadFileCommand extends Command<FileModel> {
  private readonly data: FormData;

  constructor(file: File) {
    super();

    const formData = new FormData();
    formData.append('file', file);

    this.data = formData;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/files',
      body: this.data,
    };
  }

  public parseError: undefined;

  parseResponse(response: any): FileModel {
    return response.data;
  }
}
