import type { Device, IPaginator } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Get devices command. Returns the Device.
 *
 * @returns Returns the Device. See {@link Device}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const devices = await client.run(new GetDevicesCommand());
 *
 * console.log(devices);
 * ```
 */
export class GetDevicesCommand extends Command {
  private pagination?: IPaginator;

  constructor(pagination?: IPaginator) {
    super();

    this.pagination = pagination;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.get,
      version: ApiVersion.v2,
      path: '/devices',
      query: {
        page: this.pagination?.page,
        perPage: this.pagination?.perPage,
      },
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Device[] {
    return response.data;
  }
}
