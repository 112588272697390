import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Delete event type command.
 *
 * @param eventTypeId The event type id.
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const eventType = await client.run(new DeleteEventTypeCommand("event-type-id"));
 *
 * console.log(eventType);
 * ```
 */
export class DeleteEventTypeCommand extends Command {
  private readonly eventTypeId: string;

  constructor(eventTypeId: string) {
    super();

    this.eventTypeId = eventTypeId;
  }

  public getPayload(): CommandPayload<any> {
    return {
      method: HttpMethod.delete,
      version: ApiVersion.v2,
      path: `/event-types/${this.eventTypeId}`,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>) {
    return response.data;
  }
}
