import type { Distributor, ICreateDistributorDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Create distributor command. Returns the Distributor.
 *
 * @param data Distributor data. See {@link ICreateDistributorDto}
 *
 * @returns Returns the Distributor. See {@link Distributor}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const distributor = await client.run(new CreateDistributorCommand({ ...data }));
 *
 * console.log(distributor);
 * ```
 */
export class CreateDistributorCommand extends Command {
  private readonly data: ICreateDistributorDto;

  constructor(data: ICreateDistributorDto) {
    super();

    this.data = data;
  }

  public getPayload(): CommandPayload<ICreateDistributorDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/distributors',
      body: this.data,
    };
  }

  parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Distributor {
    return response.data;
  }
}
