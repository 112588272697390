import { ICreateBoxOfficeOrderDto, Order } from '@cinesj/models';
import {
  ApiVersion,
  Command,
  CommandError,
  CommandPayload,
  HttpMethod,
} from '@cinesj/sdk/core';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Create box office order command. Returns the .
 *
 * @returns Returns the . See {@link \}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const  = await client.run(new CreateBoxOfficeOrderCommand());
 *
 * console.log();
 * ```
 */
export class CreateBoxOfficeOrderCommand extends Command {
  constructor(private readonly data: ICreateBoxOfficeOrderDto) {
    super();
  }

  public getPayload(): CommandPayload<ICreateBoxOfficeOrderDto> {
    return {
      method: HttpMethod.post,
      version: ApiVersion.v2,
      path: '/orders/box-office',
      body: this.data,
    };
  }

  public parseError(error: AxiosError<any, any>): CommandError {
    return error;
  }

  public parseResponse(response: AxiosResponse<Order, any>): Order {
    return response.data;
  }
}
