import type { Device, IUpdateDeviceDto } from '@cinesj/models';
import {
  ApiVersion,
  HttpMethod,
  Command,
  CommandPayload,
} from '@cinesj/sdk/core';
import type { AxiosResponse } from 'axios';

/**
 * Update device command. Returns the Device.
 *
 * @returns Returns the Device. See {@link Device}
 *
 * @example
 * ```ts
 * import { CinesjClient } from '@cinesj/sdk';
 *
 * const client = new CinesjClient({ baseUrl: 'https://api.cinesaojose.com.br' });
 *
 * const device = await client.run(new UpdateDeviceCommand(
 *  'device-id',
 *  {
 *    name: 'My Device',
 *    active: true,
 *  }
 * ));
 *
 * console.log(device);
 * ```
 */
export class UpdateDeviceCommand extends Command {
  private readonly deviceId: string;

  private readonly data: IUpdateDeviceDto;

  constructor(deviceId: string, data: IUpdateDeviceDto) {
    super();

    this.deviceId = deviceId;
    this.data = data;
  }

  public getPayload(): CommandPayload<IUpdateDeviceDto> {
    return {
      method: HttpMethod.put,
      version: ApiVersion.v2,
      path: `/devices/${this.deviceId}`,
      body: this.data,
    };
  }

  public parseError: undefined;

  public parseResponse(response: AxiosResponse<any, any>): Device {
    return response.data;
  }
}
